<template>
  <div>
    <b-row>
      <b-col md="6">
        <b-card no-body>
          <b-card-header>
            <b-card-title>
              <h2>HiGarage <small class="text-muted">v2.0.0</small></h2>
            </b-card-title>
          </b-card-header>
          <b-card-body class="pb-1">
            <b-card-text>
              HiGarage是嗨跑赛车面向参赛车手的一个数据服务平台，记载了每一位选手的参赛历史。
              除了参赛历史外，该数据中心还忠实记录了每位参赛选手的客观数据，例如安全分、比赛能力等。
            </b-card-text>
            <b-card-text>
              通过赛道数据库，还可以进一步了解到各个赛道在嗨跑赛车的赛事中的各组别车型的赛道记录。嗨跑数据中心将联赛、赛事、车手、赛道等数据进行高度整合。
              通过大数据展现车手的能力、赛事的进展等。具有很强的参考系性，同时也具备一定的可玩性。
            </b-card-text>
            <b-card-text>
              这个小小的数据平台也在吸取广大车手的提议，逐渐变得更强大、更好玩、更专业，也更有趣。更多功能，敬请期待。
            </b-card-text>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BCardHeader, BCardBody, BCardText, BCard,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCardHeader,
    BCardBody,
    BCardText,
    BCard,
  },
}
</script>
